.header-wrapper {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.header-container {
  width: 100%;
  max-width: 1512px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: space-between;
  padding-left: 60px;
  padding-right: 62px;
  height: 100px;
}

.logo {
  width: 317px;
  height: 70px;
  margin-top: 22px;
}

.header-container nav {
  height: 100%;
  display: flex;
  width: 45%;
}

.main-menu {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: space-between;
}

.main-menu li {
  height: 100%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  width: 75px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
  border-top: 5px solid transparent;
  cursor: pointer;
  font-size: 17px;
}

.main-menu .active {
  color: #3f8dfd;
  border-top: 6px solid #3f8dfd;
}

.register-btn {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  text-transform: capitalize;
  cursor: pointer;
  border-radius: 4px;
  background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
}

.toggle-menu {
  display: none;
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@media (max-width: 1200px) {
  .header-container nav {
    width: 55%;
  }
}

@media (max-width: 960px) {
  .header-container {
    padding: 10px 40px;
    z-index: 100;
  }

  .main-menu {
    display: none;
  }

  .header-wrapper {
    margin-bottom: 15px;
  }

  .logo {
    width: 200px;
    height: 50px;
    margin-top: 0;
  }
  .toggle-menu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .toggle-menu nav {
    box-shadow: 0px 11.2228px 12.0861px rgba(0, 0, 0, 0.14);
  }

  .toggle-menu {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .toggle-menu .mobile-menu {
    list-style: none;
  }

  .toggle-menu .mobile-menu li {
    cursor: pointer;
    padding-bottom: 10px;
    font-size: 17px;
  }

  .toggle-menu .mobile-menu li:hover {
    color: #3f8dfd;
  }

  .mobile-menu .active {
    color: #3f8dfd;
    border-bottom: 2px solid #3f8dfd;
  }

  .mobile-rgister-btn,
  .mobile-register-btn a {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    text-transform: capitalize;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
    background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
  }

  .toggle-menu .box {
    width: 18px;
    height: 16px;
    cursor: pointer;
    z-index: 99;
  }

  .navBtn {
    width: 18px;
    height: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
  }

  .box .first,
  .box .third {
    width: 18px;
    height: 3px;
    background-color: #010101;
    transition: all 0.3s ease;
  }

  .not-active .small {
    width: 15px;
    height: 2px;
    background-color: #010101;
    opacity: 1;
    transition: all 0.3s ease;
  }

  .active .first {
    position: absolute;
    top: 50%;
    left: calc(50% - 9px);
    transform: translate(-50%, -50%);
    transform: rotate(45deg);
  }

  .active .third {
    position: absolute;
    top: 50%;
    left: calc(50% - 9px);
    transform: translate(-50%, -50%);
    transform: rotate(-45deg);
  }

  .active .small {
    opacity: 0;
  }

  .mobile-menu {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    padding: 90px 50px;
    background-color: #ffffff;
    z-index: 90;
    animation: slide-in 0.4s ease;

    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
  }
}

@media (max-width: 560px) {
  .header-container {
    padding: 22px;
    margin-bottom: 10px;
  }

  .logo {
    width: 141px;
    height: 32px;
    margin-top: 0;
  }
}
