.games-container {
  width: 100%;
  max-width: 1512px;
  margin: 0 auto;
  padding: 120px 62px 90px 60px;
  display: flex;
  align-items: center;
  gap: 64px;
}

.games-container-content {
  width: 38%;
  max-width: 30vw;
}

.games-container-content h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 80px;
  color: #000000;
}

.games-container-content h2 span {
  background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.games-container-content p {
  margin-top: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.6);
}

.games-container-cards {
  display: flex;
  gap: 25px;
  width: 100%;
  padding: 30px 0;
}

.game-card {
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 34px 24px -26px rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 8px 16px;
}

.game-card img {
  width: 100%;
}

.card-contents {
  display: flex;
  flex-direction: column;
  padding: 11px;
}

.gm-qstn {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.progress-bar {
  width: 100%;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 20px 0;
}

.progress-bar .progress {
  height: 100%;
  border-radius: 4px;
  background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
}

.seat-info,
.prize-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.prize-info {
  margin-top: 10px;
}

.seat-info p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.prize-info p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.prize-info p .hl {
  color: rgba(0, 0, 0, 0.2);
}

.join-btn {
  margin-top: 15px;
  margin-left: auto;
  padding: 10px 20px;
  background-color: #bbc2cc;
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  width: fit-content;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.join-btn:hover {
  background-color: #b2b9c2;
}

@media (max-width: 1330px) {
  .games-container-content h2 {
    font-size: 50px;
    line-height: 60px;
  }
}

@media (max-width: 1100px) {
  .games-container-cards {
    width: 100%;
    overflow-x: auto;
  }

  .game-card {
    min-width: 275px;
  }
}

@media (max-width: 960px) {
  .games-container {
    padding: 80px 40px;
    flex-direction: column;
  }

  .games-container-content {
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 560px) {
  .games-container {
    padding: 30px 22px 30px 22px;
    flex-direction: column;
  }

  .games-container-content {
    width: 100%;
    text-align: center;
  }

  .games-container-content h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .games-container-cards {
    width: 100%;
    overflow-x: auto;
  }
}
