.download-container {
  background-image: url('../assets/images/download-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 346px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.download-container h3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
}

.download-container .download-store-buttons {
  margin-top: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.download-store-buttons img {
  width: 30%;
}

.grow {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.grow:hover {
  transform: scale(1.1);
}

@media (max-width: 960px) {
  .download-container h3 {
    font-size: 36px;
    line-height: 45px;
  }

  .download-container img {
    width: 40%;
  }
}

@media (max-width: 560px) {
  .download-container {
    text-align: center;
  }

  .download-container h3 {
    font-size: 34px;
    line-height: 40px;
  }

  .download-container .store-buttons {
    align-items: center;
    gap: 10px;
    justify-content: center;
  }
}
