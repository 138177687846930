.influencer-container {
  width: 100%;
  background-color: rgba(63, 141, 253, 0.1);
}

.influencer-wrapper {
  width: 100%;
  max-width: 1512px;
  margin: 0 auto;
  padding: 45px 60px 50px 26px;
  display: flex;
  align-items: center;
  position: relative;
}

.influencer-hero {
  width: 100%;
  max-width: 30vw;
  z-index: 10;
}

.influencer-content {
  display: flex;
  width: 100%;
  z-index: 15;
  gap: 26px;
  margin-left: -12%;
}

.influencer-content-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-card {
  width: 320px;
  background: #d1e1f8;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: 0px 34px 44px -20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 16px 29px 10px 28px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.input-card-bg {
  position: absolute;
  top: 0;
  left: 0;
}

.input-card h4 {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 18px;
  margin-bottom: 24px;
}

.quiz-qstn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 13px;
}

.input-box {
  position: relative;
  margin-bottom: 25px;
}

.highlighted-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #ec6a20;
}

.host-content-btns {
  align-items: center;
  display: flex;
  text-align: center;
  gap: 16px;
  margin-top: 28px;
}

.host-apply-btn {
  background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
  border: none;
  border-radius: 4px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  padding: 10px 20px;
}

.host-download-button {
  color: #2663da;
}
.host-download-button:hover {
  color: #2663da;
  text-decoration: underline;
}

.host-text-wrapper {
  position: relative;
}

.slant-host-text {
  position: absolute;
  left: 40%;
  top: -5%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #ec6012;
  transform: rotate(-12.05deg);
}
.slant-play-text {
  position: absolute;
  left: 0;
  top: -10%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;
  text-decoration-line: underline;
  color: #ec6012;
  transform: rotate(-12.05deg);
}

.draw-textarea {
  resize: none;
  height: 99px;
  border: none;
  text-align: center;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.draw-textarea:focus {
  outline: 0;
}

.draw-textarea::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.1);
}

.visible-edit-icon {
  display: block;
  position: absolute;
  bottom: 7px;
  right: 10px;
  cursor: pointer;
}

.invisible-edit-icon {
  display: none;
}

.quiz-pagination {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.winner-box {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 9px;
  background: #ffffff;
  border-radius: 6px;
  margin-bottom: 10px;
}

.winner-box .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3529px;
  line-height: 28px;
}

.winner-box .prize {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18.3529px;
  line-height: 28px;
  color: #20c997;
}

.second-winner-box {
  background: #ffffff;
  opacity: 0.6;
  border-radius: 4.94118px;
  display: flex;
  padding: 7px;
  gap: 14px;
  align-items: center;
  margin-bottom: 10px;
}

.second-winner-box .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14.8235px;
  line-height: 22px;
}

.second-winner-box .prize {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14.8235px;
  line-height: 22px;
}

.third-winner-box {
  background: #ffffff;
  opacity: 0.4;
  border-radius: 4.94118px;
  display: flex;
  padding: 6px;
  gap: 10px;
  align-items: center;
}

.third-winner-box .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.third-winner-box .prize {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.influencer-content-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 70px;
  line-height: 80px;
  margin-bottom: 20px;
}

.influencer-content-title span {
  background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.influencer-content-dcrptn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.6);
}

.information-box {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
}

.information-box p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.6);
}

.information-box p span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.6);
}

.hor-ln {
  height: 49px;
  width: 2px;
  margin: auto 0;
  background-color: #5e6266;
}

.youtube-icon {
  position: absolute;
  top: 20%;
  right: 0;
  transform: translate(-50%, -50%);
}
.youtube-icon path {
  fill: rgba(0, 0, 0, 0.2);
}

.instagram-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: 0;
  right: 15%;
}

.tiktok-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 12%;
  left: 50%;
}

.facebook-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 20%;
  left: 7%;
  opacity: 0.2;
}

.twitter-icon {
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: 4%;
  left: 30%;
}

@media (max-width: 1330px) {
  .influencer-content-title {
    font-size: 50px;
    line-height: 58px;
  }

  .input-card {
    width: 240px;
    padding: 10px;
  }

  .draw-textarea {
    height: 99px;
  }
}

@media (max-width: 960px) {
  .influencer-hero {
    width: 50%;
    max-width: 100%;
  }

  .influencer-wrapper {
    flex-direction: column;
  }
  .influencer-content {
    flex-direction: column;
    gap: 18px;
    margin-left: 0;
    margin-top: -120px;
  }

  .influencer-content-right {
    text-align: center;
  }

  .influencer-content-title {
    font-size: 32px;
    line-height: 150%;
  }

  .influencer-content-dcrptn {
    font-size: 18px;
    line-height: 150%;
  }

  .youtube-icon {
    width: 120px;
  }
  .facebook-icon {
    width: 60px;
  }

  .instagram-icon {
    width: 70px;
  }

  .twitter-icon {
    width: 40px;
  }

  .information-box {
    margin-top: 20px;
    width: 60%;
    margin: 20px auto;
  }

  .information-box p {
    font-size: 18px;
    line-height: 120%;
  }

  .information-box p span {
    font-size: 12px;
    line-height: 120%;
  }

  .hor-ln {
    height: 36px;
  }
}

@media (max-width: 560px) {
  .influencer-wrapper {
    padding: 25px 12px 110px 12px;
    flex-direction: column;
  }

  .influencer-hero {
    width: 100%;
    max-width: 100%;
  }

  .influencer-content {
    flex-direction: column;
    margin: 0 auto;
  }

  .influencer-content-left {
    margin-top: -100px;
    padding: 0 23px;
  }

  .input-card {
    width: 100%;
    max-width: 320px;
    padding: 16px 28px 10px 28px;
  }

  .facebook-icon {
    top: 5%;
    left: 10%;
  }

  .tiktok-icon {
    top: 7%;
    left: 75%;
  }

  .twitter-icon {
    bottom: 25%;
    left: 18%;
  }

  .youtube-icon {
    top: 52%;
    right: 0;
  }

  .influencer-content-right {
    margin-top: 10px;
    padding: 0 8px;
  }

  .influencer-content-title {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }

  .influencer-content-dcrptn {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
  }

  .information-box {
    width: 100%;
  }

  .information-box p {
    font-size: 26px;
    line-height: 28px;
  }

  .information-box p span {
    font-size: 12px;
    line-height: 28px;
  }

  .hor-ln {
    height: 49px;
  }
}
