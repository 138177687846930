.modal-container {
  position: fixed;
  width: 50%;
  max-width: 720px;
  min-height: 45%;
  top: 50%;
  left: 50%;
  border-radius: 16px;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 1000;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.4s ease;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 30px 20px 0 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  padding-left: 20%;
}

.modal-header p {
  height: 100%;
  padding: 0 15px 10px 15px;
  border-bottom: 2px solid #ffffff;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  font-size: 17px;
  transition: all 0.3s ease-in-out;
}

.modal-header p:hover {
  color: rgba(63, 141, 253, 0.4);
}

.modal-header .active {
  color: #3f8dfd;
  border-bottom: 2px solid #3f8dfd;
}

.modal-header .active:hover {
  color: #3f8dfd;
}

.modal-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 20px 15% 30px 15%;
}

.fade-in-animation {
  animation: fade-in 0.3s ease-in-out;
}

@keyframes fade-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.fade-out-animation {
  animation: fade-out 0.3s ease-in-out;
}

@keyframes fade-out {
  0% {
    transform: translateX(0);
    opacity: 0;
  }
  100% {
    transform: translateX(-100%);
    opacity: 1;
  }
}

.modal-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.email-wrapper,
.password-wrapper {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  transition: all 0.3s ease;
}

.modal-form label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
  font-size: 15px;
  transition: all 0.3s ease;
}

.modal-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: all 0.3s ease;
}

.image-input-wrapper {
  display: flex;
  gap: 10px;
  transition: all 0.3s ease;
}

.modal-input-wrapper .options {
  display: flex;
  gap: 20px;
  align-items: center;
  height: fit-content;
}

.modal-input-wrapper .option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal-input-wrapper .option input {
  height: min-content;
}

.input-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.4);
  font-size: 15px;
  transition: all 0.3s ease;
}

.modal-form label input {
  height: unset;
  margin-right: 0.5rem;
}
.modal-form label   a {
  color:#2663da
}


.modal-form input {
  height: 46px;
  padding: 10px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.modal-form input::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.4;
  line-height: 22px;
}

.email-wrapper input:focus + label,
.password-wrapper input:focus + label,
.modal-input-wrapper input:focus + label {
  color: #3f8dfd;
}

.password-input-wrapper {
  width: 100%;
  position: relative;
}

.password-input-wrapper input {
  width: 100%;
}

.password-icon {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.4;
}

.modal-form .logIn-btn,
.verify-btn {
  font-family: 'Inter';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding: 10px 20px;
  margin: 0 auto;
  width: min-content;
  min-width: 130px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
  margin-bottom: 15px;
}

.icon-wrapper {
  position: fixed;
  top: 5%;
  right: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  z-index: 9999;
}

.cross-icon {
  height: 24px;
  width: 24px;
  filter: drop-shadow(0px 7px 29px 0px rgb(100 100 111 / 0.2));
  transition: all 0.3s ease;
}

.cross-icon path {
  fill: #fc3c3c;
}

.form-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logged-in-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

#keep-logged-in {
  height: fit-content;
}
.modal-form .logged-in-label {
  font-size: 12px;
}

.forgot-txt {
  font-size: 12px;
  color: #3f8dfd;
  cursor: pointer;
}

.forgot-txt:hover {
  text-decoration: underline;
}

.account-txt {
  font-family: 'Inter';
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.account-txt span {
  color: #3f8dfd;
}

.account-txt:hover span {
  text-decoration: underline;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.divider {
  font-size: 13px;
  line-height: 1.69231;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  position: relative;
  width: 100%;
  text-align: center;
}

.divider::before {
  background-color: #f1f2f6;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 45%;
  right: 0.5em;
  margin-left: -50%;
}

.divider::after {
  background-color: #f1f2f6;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 45%;
  left: 0.5em;
  margin-right: -50%;
}

.login-icons {
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.login-icons a {
  flex: 1;
}

.login-icon {
  width: 100%;
  cursor: pointer;
}

.forgot-container,
.username-container {
  height: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade-up 0.3s ease-in-out;
}

@keyframes fade-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.forgot-header,
.username-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 17px;
  color: #3f8dfd;
  margin: 20px;
  text-align: center;
}

.login-txt {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  color: #3f8dfd;
  text-align: end;
  cursor: pointer;
  transition: all 0.3s ease;
}

.login-txt:hover {
  text-decoration: underline;
}

.email-success-txt {
  font-family: 'Inter';
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: rgba(252, 60, 60, 0.5);
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.rv {
  flex-direction: column-reverse;
}

.followers-group {
  display: block;
  width: 100%;
  height: 2.625rem;
  padding: 0.375rem 1.2rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.4);
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #f1f2f6;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.error-message {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  color: #fc3c3c;
  text-align: center;
  margin-bottom: 20px;
  text-transform: capitalize;
  animation: shake 0.3s ease 3 alternate;
}

@keyframes shake {
  0% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translate(0);
  }
}

.success-text {
  font-family: 'Inter';
  font-size: 18px;
  font-weight: 400;
  color: #3f8dfd;
  text-align: center;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.upload-button {
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  border: 1px solid #e3e6ef;
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  width: fit-content;
  border-radius: 4px;
}

.uploaded-image {
  width: 150px;
  max-height: 150px;
  object-fit: cover;
  background-position: center;
  padding: 4px;
  border: 1px solid #e3e6ef;
}

.upload-button svg path {
  fill: rgba(0, 0, 0, 0.4);
}

.image-box {
  width: 100px;
  height: 100px;
  border: 1px solid blue;
}

.d-f {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-body .verify-title {
  font-family: 'Inter';
  font-size: 24px;
  line-height: 1.25;
  font-weight: 600;
  text-align: center;
}

.modal-body .verify-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #666d92;
  text-align: left;
  text-align: center;
  margin-bottom: 40px;
}

.verify-form .input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.verify-form input {
  height: 46px;
  padding: 5px;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}

.verify-form .verify-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.image-upload-box {
  display: flex;
  flex-direction: column;
}

.image-upload-box p {
  transition: all 0.3s ease;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 5px;
  border-radius: 4px;
}

.image-upload-box p:hover {
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  background-color: rgba(63, 141, 253, 0.1);
}

.image-upload-box p svg path {
  fill: (rgba(0, 0, 0, 0.4));
  opacity: 0.4;
}

.image-upload-box p:hover svg path {
  fill: #3f8dfd;
  opacity: 0.7;
}

.resend-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
}

.link-text {
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  color: #3f8dfd;
  margin-bottom: 20px;
  text-transform: capitalize;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: right;
}

.norm-text {
  font-family: 'Inter';
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  text-transform: capitalize;
  width: 100%;
  transition: all 0.3s ease;
}

.dn {
  display: none;
}

.link-text:hover {
  text-decoration: underline;
  transform: translate(5px);
}

@media only screen and (max-width: 768px) {
  .modal-container {
    width: 70%;
    max-height: 100%;
  }

  .modal-header {
    padding-left: 5%;
  }

  .modal-body {
    padding: 20px 10% 30px 10%;
  }
}

@media only screen and (max-width: 425px) {
  .modal-container { 
    width: 90vw;
    left: 38%;
    top: 40%;
}

  .modal-header {
    padding-left: 5%;
  }

  .modal-body {
    padding: 20px 5% 20px 5%;
  }

  .cross-icon {
    height: 18px;
    width: 18px;
  }

  .modal-form label {
    font-size: 14px;
  }

  .modal-form input {
    font-size: 14px;
    padding: 6px 10px;
    height: 35px;
  }

  .password-icon {
    width: 16px;
    height: 16px;
  }
}
