.footer-container {
  width: 100%;
  background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
}

.footer-wrapper {
  width: 100%;
  max-width: 1512px;
  padding: 30px 62px 30px 60px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  gap: 150px;
  padding-bottom: 50px;
}

.footer-content-left,
.footer-content-center,
.footer-content-right {
  flex: 1;
}

.payment-txt {
  font-family: "Poppins";
  margin-top: 23px;
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  color: #ffffff;
}

.payment-icons {
  margin-top: 30px;
  display: flex;
  gap: 22px;
}

.payment-icon {
  cursor: pointer;
  transition: all 0.3s ease;
}

.payment-icon:hover {
  transform: scale(1.1);
}

.footer-dscrptn {
  margin-top: 38px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  color: #ffffff;
}

.social-icons {
  display: flex;
  margin-top: 20px;
  gap: 28px;
}

.social-icon {
  cursor: pointer;
  transition: all 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
}

.help-title {
  font-family: "Inter";
  font-weight: 600;
  font-size: 20px;
  line-height: 50px;
  color: #ffffff;
}

.footer-content-center {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.help-lnk {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer-txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
}

.footer-content-title {
  font-size: 18px;
  line-height: 24px;
  margin-top: 3rem;
  color: #fff;
  font-family: Inter;
  font-style: normal;
  margin-bottom: 0.5rem;
}

.help-lnk:hover {
  text-decoration: underline;
}

.footer-content-right {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
}

.subscribe-txt {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 50px;
  color: #ffffff;
}

.input-wrapper {
  width: 100%;
  display: flex;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 1rem;
}

.input-fld {
  width: 80%;
  padding: 10px;
  background: transparent;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.input-fld:focus {
  outline: none;
}

.input-fld::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.7);
}

.footer-btn {
  flex: 1;
  border: none;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 6px;
  padding: 0 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.footer-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.copyright-box {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  gap: 10px;
}

.copyright-box p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
}

.copyright-box p span {
  font-weight: 700;
}

@media (max-width: 1330px) {
  .footer-content {
    gap: 70px;
  }

  .payment-txt {
    font-size: 18px;
    line-height: 24px;
  }

  .footer-dscrptn {
    margin-top: 30px;
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 960px) {
  .footer-content {
    gap: 4%;
    width: 100%;
  }

  .footer-content-center,
  .footer-content-left,
  .footer-content-right {
    width: 33%;
    flex-basis: 100%;
  }

  .footer-logo {
    width: 200px;
  }
}

@media (max-width: 720px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
    gap: 60px;
  }

  .footer-content-left,
  .footer-content-right,
  .footer-content-center {
    width: 100%;
    flex-basis: 100%;
  }

  .footer-content-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-content-center {
    text-align: center;
  }

  .footer-content-right {
    text-align: center;
  }
}

@media (max-width: 560px) {
  .footer-wrapper {
    padding: 50px 20px 40px 20px;
  }

  .footer-logo {
    width: 216px;
  }

  .payment-txt {
    margin-top: 30px;
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 35px;
  }

  .payment-icons {
    margin-top: 0;
  }

  .footer-dscrptn {
    font-size: 16px;
    line-height: 26px;
  }

  .social-icons {
    margin-top: 12px;
  }
}
