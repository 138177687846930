.hero-container {
  width: 100%;
  max-width: 1512px;
  display: flex;
  margin: 0 auto;
  padding: 0 0 0 60px;
}

.main-content {
  display: flex;
  width: calc(100% - 62px);
  gap: 24px;
}

.social-bar {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.icon-sm {
  cursor: pointer;
  transition: all 0.3s ease;
}

.icon-sm:hover path {
  fill: #2663da;
}

.hero-content {
  width: 45%;
}

.hero-title {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 800;
  line-height: 67px;
  color: #000000;
}

.hero-title .large-text {
  font-size: 90px;
  line-height: 100px;
}

.hero-description {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 23px;
}

.content-buttons {
  margin-top: 43px;
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.store-buttons,
.other-buttons {
  display: flex;
  gap: 30px;
}

.store-buttons img {
  width: 35%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.other-buttons button {
  transition: all 0.3s ease;
}

.store-buttons img:hover,
.other-buttons button:hover {
  transform: scale(1.05);
  box-shadow: 0px 34px 24px -26px rgba(0, 0, 0, 0.1);
}

.learn-btn {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%) border-box;
  border: 2px solid transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 35%;
  height: 57px;
  cursor: pointer;
}

.learn-btn span {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  background: linear-gradient(138.07deg, #548cf5 0.18%, #2663da 100.76%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.demo-btn {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  border: 2px solid #fc3c3c;
  color: #fc3c3c;
  width: 35%;
  height: 57px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
}

.hero-images {
  flex: 1;

  position: relative;
  width: 100%;
}

.hero-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
}

.hero-image {
  width: calc(50% - 10px);
  object-fit: cover;
}

.persons {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 264px;
}

.hero-winner-box {
  display: flex;
  align-items: center;
  padding: 11px 16px 11px 18px;
  background-color: #ffffff;
  box-shadow: 0px 17.027px 18.3368px rgba(0, 0, 0, 0.14);
  border-radius: 7.48442px;
  width: fit-content;
}

.hero-winner-box img {
  margin-right: 19px;
  width: 38px;
  height: 38px;
}

.winner-info {
  margin-right: 15px;
}

.winner-info .person-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22.4532px;
  line-height: 27px;
}

.winner-info .person-standing {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14.9688px;
  line-height: 18px;
  opacity: 0.6;
}

.winner-info .person-standing span {
  color: #00ac79;
}

.winner-prize {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14.9688px;
  line-height: 18px;
  padding: 10px 8px;
  background-color: #f9ac3a;
  border-radius: 5.61331px;
  color: #ffffff;
}

.hero-second-box {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: #ffffff;
  padding: 9px 11px 9px 16px;
  box-shadow: 0px 13.9404px 15.0127px rgba(0, 0, 0, 0.14);
  border-radius: 6px;
  margin-left: -30px;
  margin-top: 14px;
  margin-bottom: 14px;
  width: fit-content;
}

.hero-second-box img {
  width: 30px;
  height: 30px;
  margin-right: 16px;
}

.second-info {
  margin-right: 28px;
}

.second-info .person-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18.383px;
  line-height: 22px;
}

.second-info .person-standing {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12.2553px;
  line-height: 15px;
  opacity: 0.6;
}

.second-info .person-standing span {
  color: #004ee0;
}

.second-prize {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12.2553px;
  line-height: 15px;
  padding: 7px 6px;
  background: #f9ac3a;
  border-radius: 4px;
  color: #ffffff;
}

.hero-third-box {
  display: flex;
  align-items: center;
  padding: 7px 9px;
  background-color: #ffffff;
  box-shadow: 0px 11.2228px 12.0861px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  width: fit-content;
  margin-left: -5px;
}

.hero-third-box img {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.third-info {
  margin-right: 26px;
}

.third-info .person-name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14.7993px;
  line-height: 18px;
}

.third-info .person-standing {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 9.86621px;
  line-height: 12px;
  opacity: 0.6;
}

.third-info .person-standing span {
  color: #e99a00;
}

.third-prize {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 9.86621px;
  line-height: 12px;
  padding: 6px 4px;
  background: #f9ac3a;
  border-radius: 3px;
  color: #ffffff;
}

@media (max-width: 1330px) {
  .hero-title {
    font-size: 36px;
    line-height: 44px;
  }

  .hero-title .large-text {
    font-size: 70px;
    line-height: 80px;
  }
}

@media (max-width: 1250px) {
  .store-buttons img {
    /* height: 45px; */
    width: calc(50% - 16px);
  }

  .learn-btn,
  .demo-btn {
    width: 50%;
  }
  .learn-btn span,
  .demo-btn {
    font-size: 18px;
  }

  .learn-btn svg,
  .demo-btn svg {
    height: 18px;
  }
}

@media (max-width: 960px) {
  .hero-container {
    padding: 0 40px;
    margin-bottom: 80px;
  }

  .social-bar {
    display: none;
  }

  .main-content {
    width: 100%;
    flex-direction: column-reverse;
    gap: 50px;
  }

  .hero-content {
    width: 100%;
  }

  .hero-title {
    font-size: clamp(26px, 4vw, 38px);
    line-height: 150%;
  }

  .hero-title .large-text {
    font-size: clamp(42px, 6vw, 58px);
    line-height: 150%;
  }

  .hero-description {
    font-size: 22px;
  }

  .content-buttons {
    flex-direction: row;
    margin-top: 30px;
    gap: 16px;
    align-items: center;
  }

  .store-buttons,
  .other-buttons {
    width: 50%;
    gap: 14px;
  }

  .store-buttons img {
    width: calc(50% - 7px);
  }

  .other-buttons button {
    width: 50%;
    height: 45px;
  }

  .learn-btn span,
  .demo-btn {
    font-size: 18px;
    line-height: 20px;
  }

  .learn-btn svg {
    width: 24px;
    height: 20px;
  }

  .hero-images {
    gap: 15px;
  }

  .hero-image {
    max-width: 100%;
    width: calc(50% - 20px);
    margin: 0 auto;
  }
}

@media (max-width: 720px) {
  .learn-btn span,
  .demo-btn {
    font-size: 14px;
  }

  .learn-btn svg,
  .demo-btn svg {
    height: 14px;
  }
}

@media (max-width: 575px) {
  .hero-container {
    padding: 0 22px;
    margin-bottom: 60px;
  }

  .content-buttons {
    flex-direction: column;
  }

  .store-buttons,
  .other-buttons {
    width: 100%;
  }

  /* .other-buttons button {
    width: 141px;
    height: 37px;
  } */

  .hero-description {
    margin-top: 10px;
    font-size: 18px;
  }

  .learn-btn span,
  .demo-btn {
    font-size: 14px;
    line-height: 17px;
  }

  .learn-btn svg,
  .demo-btn svg {
    width: 21px;
    height: 15px;
  }
  .hero-image {
    width: calc(50% - 11px);
  }
}
