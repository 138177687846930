.hosts-container {
  width: 100%;
  max-width: 1512px;
  margin: 0 auto;
  padding: 120px 62px 120px 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 38px;
  column-gap: 37px;
}

.image-wrapper {
  position: relative;
}

.hosts-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.host-content {
  position: absolute;
  bottom: 6px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(14px);
  border-radius: 12px;
  padding: 10px;
  text-align: end;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.host-content h4 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  color: #ffffff;
}

.host-content p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: #ffffff;
}

.host-content p span {
  font-size: 12px;
}

@media (max-width: 900px) {
  .hosts-container {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 40px;
    column-gap: 30px;
    row-gap: 30px;
    margin-bottom: 60px;
  }
}

@media (max-width: 560px) {
  .hosts-container {
    padding: 0 22px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
  }

  .image-wrapper {
    height: 170px;
  }

  .image-wrapper img {
    object-fit: cover;
    border-radius: 6px;
  }

  .host-content h4 {
    font-size: 16px;
  }

  .host-content p {
    font-size: 12px;
  }
}
